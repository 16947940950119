import React from "react";
import Link from "next/link";
import { TextSafePalette } from "./constants";
import { TinaMarkdown, TinaMarkdownContent } from "tinacms/dist/rich-text";
import Figure from "./Figure";

type HeroProps = {
  pageTheme: TextSafePalette;
  theme?: TextSafePalette;
  title: string;
  intro?: TinaMarkdownContent;
  ctaText?: string;
  ctaLink?: string;
  image?: string;
  imageAlt?: string;
  jumplinks?: {
    title: string;
    id: string;
  }[];
  isHomePageHero?: boolean;
};

const Hero = ({
  pageTheme,
  theme = pageTheme,
  title,
  intro,
  ctaText,
  ctaLink,
  image,
  imageAlt,
  jumplinks,
  isHomePageHero = false,
}: HeroProps) => {
  return (
    <section className={`hero block theme--${theme} `}>
      <div className="text-content">
        <h1 className={isHomePageHero ? "hero__homepage-heading" : undefined}>
          {title}
        </h1>
        {intro && <TinaMarkdown content={intro} />}
        {ctaLink && (
          <Link className="btn" href={ctaLink}>
            {ctaText}
          </Link>
        )}
        {jumplinks && jumplinks.length > 0 && (
          <div className="jumplinks">
            <h2>On this page</h2>
            <ul>
              {jumplinks.map(({ title, id }) => (
                <li key={id}>
                  <a href={"#" + id}>
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m12 4v20m-6-6 6 6 6-6"
                      />
                    </svg>
                    <span>{title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {image && <Figure image={image} imageAlt={imageAlt} isAboveFold={true} />}
    </section>
  );
};

export default Hero;
