import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { TextSafePalette } from "./constants";
import { generateSlug } from "../utils/slugify";

type MapProps = {
  heading?: string;
  longitude?: number;
  latitude?: number;
  address?: string;
  pageTheme: TextSafePalette;
  theme?: TextSafePalette;
  removeKidzoneMarker?: boolean;
  showInJumplinks?: boolean;
};

const Map = ({
  heading,
  longitude,
  latitude,
  address,
  pageTheme,
  theme = pageTheme,
  removeKidzoneMarker = false,
  showInJumplinks,
}: MapProps) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [zoom] = useState(15);
  const lng = longitude || 174.785343;
  const lat = latitude || -41.315142;

  useEffect(() => {
    if (typeof window === "undefined" || map.current) return;

    mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN || "";

    console.log(mapboxgl.accessToken);

    const initializeMap = () => {
      if (!mapContainer.current) return;

      map.current = new mapboxgl.Map({
        container: mapContainer.current!,
        style: "mapbox://styles/poneke/clhq24aml002p01oh3g40002m",
        center: [lng, lat],
        zoom,
      });

      map.current.on("load", () => {
        if (!map.current) return;

        const el = document.createElement("div");
        el.innerHTML = removeKidzoneMarker
          ? `<svg width="27px" height="31px" viewBox="0 0 72 82">
  <path fill="#0088c7" stroke="white"
    d="m64.6 29.9c0 23.4-28.6 50.8-28.6 50.8s-28.4-27.4-28.4-50.8c0-15.8 12.6-28.6 28.4-28.6s28.6 12.8 28.6 28.6z" />
  <circle fill="white" cx="36" cy="30.1" r="20" />
</svg>`
          : `<svg width="72px" height="48px" viewBox="0 0 192 128">
          <g transform="scale(3) translate(2.5,0)" fill="#0088c7">
            <path d="M.5 1H4l-.2 3.6L6.2 1l3.5.2-3.5 5.4 3 4.8-3.4.6-2.3-4v4l-3.1.2L.5 1" />
            <path d="M10.1 1.6H14l-.4 10-3.4.1v-10" />
            <path
              d="M18 1.2c-.9 0-2 .2-3.1.6v10l3.2-.1c6.5-.6 7.5-10.6 0-10.5zm.3 3c1.5 0 2 1.1 1.9 2.2 0 1.2-.6 2.6-2.4 2.6l.2-4.8h.3z" />
            <path d="M24 1.2h6.6V4l-3.4 4.7h3.2v2.8l-6.8.3v-3l4.1-5H24z" />
            <path
              d="M36 1.9h-.3c-3.3.1-4.7 2.5-4.7 5 0 2.3 1.8 4.8 4.8 4.8s4.6-3 4.7-5.2C40.5 4.4 39 2 36 2zm-.4 3h.1c2.4 0 2.4 3.8 0 3.8S33.4 5 35.7 5z" />
            <path d="M41.3 11.7V1.2h3.4L48 6.3v-5l3.3-.1-.2 10.3-3.3.2-3.1-5.4-.1 5.4z" />
            <path d="M59 1.2h-6.7v10.5h6.4v-3h-3.3V7.1h2.5v-2h-2.4V3.8H59z" />
          </g>

          <path fill="#f05c74" stroke="white"
            d="m124.6 76.9c0 23.4-28.6 50.8-28.6 50.8s-28.4-27.4-28.4-50.8c0-15.8 12.6-28.6 28.4-28.6s28.6 12.8 28.6 28.6z" />
          <circle fill="white" cx="96" cy="77.1" r="20" />
        </svg>
        `;

        new mapboxgl.Marker({ element: el, anchor: "bottom" })
          .setLngLat([lng, lat])
          .addTo(map.current);

        const nav = new mapboxgl.NavigationControl();
        map.current.addControl(nav, "top-left");
        map.current.setCenter([lng, lat]);
      });
    };

    initializeMap();

    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  }, [lng, lat, zoom]);

  return (
    <section className={`map block full-width theme--${theme || "blue"}`}>
      {heading && (
        <h2 id={showInJumplinks ? generateSlug(heading) : undefined}>
          {heading}
        </h2>
      )}
      <figure>
        <div ref={mapContainer} className="map__container" />
        {address && <figcaption>{address}</figcaption>}
      </figure>
    </section>
  );
};

export default Map;
