import React from "react";
import { TextSafePalette } from "./constants";
import Text, { TextProps } from "./Text";
import Table, { TableProps } from "./Table";
import { generateSlug } from "../utils/slugify";

export type ContentBlock =
  | ({ __typename: "PageBlocksBodyTextContentText" } & TextProps)
  | ({ __typename: "PageBlocksBodyTextContentTable" } & TableProps);

type BodyTextProps = {
  pageTheme: TextSafePalette;
  theme?: TextSafePalette;
  heading?: string;
  content?: ContentBlock[];
  showInJumplinks?: boolean;
};

const BodyText = ({
  pageTheme,
  theme = pageTheme,
  heading,
  content,
  showInJumplinks,
}: BodyTextProps) => {
  return (
    <section className={`body-text block full-width theme--${theme}`}>
      {heading && (
        <h2 id={showInJumplinks ? generateSlug(heading) : undefined}>
          {heading}
        </h2>
      )}
      {content &&
        content.length > 0 &&
        content.map((block, index) => {
          switch (block.__typename) {
            case "PageBlocksBodyTextContentText":
              const {
                heading: textHeading,
                richText,
                ctaLink,
                ctaText,
              } = block;
              return (
                <Text
                  key={index}
                  heading={textHeading}
                  richText={richText}
                  ctaText={ctaText}
                  ctaLink={ctaLink}
                  HeadingLevel={heading ? "h3" : "h2"}
                />
              );
            case "PageBlocksBodyTextContentTable":
              const { heading: tableHeading, intro, rows } = block;
              return (
                <Table
                  key={index}
                  heading={tableHeading}
                  intro={intro}
                  rows={rows}
                  HeadingLevel={heading ? "h3" : "h2"}
                />
              );
            default:
              return null;
          }
        })}
    </section>
  );
};

export default BodyText;
