import React, { FC, useMemo } from "react";
import { HEX_CODES, Palette, TextSafePalette } from "./constants";

export const SHAPE_LAYOUTS = [
  "squareBottomRight_SR",
  "semiCircleTopRight_S",
  "squareTopLeft_TC",
  "triangleBottomRight_SR",
  "circleTopLeft_TR",
  "triangleTopCentre_S",
  "squareTopRight_CR",
] as const;

export type ShapeLayout = (typeof SHAPE_LAYOUTS)[number];

type CompositeImageProps = {
  src: string;
  layout: ShapeLayout;
  theme?: TextSafePalette;
};

type LayoutConfig = {
  clipPath: FC;
  image: {
    x: string;
    y: string;
    size: string;
  };
  shapes: FC<{ colors: Palette[] }>;
};

export type ColorCombo = Record<ShapeLayout, Palette[]>;

const colorCombos: Record<TextSafePalette, ColorCombo> = {
  blue: {
    squareBottomRight_SR: ["yellow", "darkBlue"],
    semiCircleTopRight_S: ["yellow"],
    squareTopLeft_TC: ["lightBlue", "pink"],
    triangleBottomRight_SR: ["lightBlue", "red"],
    circleTopLeft_TR: ["lightBlue", "orange"],
    triangleTopCentre_S: ["orange"],
    squareTopRight_CR: ["lightBlue", "red"],
  },
  orange: {
    squareBottomRight_SR: ["lightBlue", "lightPurple"],
    semiCircleTopRight_S: ["blue"],
    squareTopLeft_TC: ["blue", "red"],
    triangleBottomRight_SR: ["lightPurple", "darkBlue"],
    circleTopLeft_TR: ["lightBlue", "orange"],
    triangleTopCentre_S: ["lightPurple"],
    squareTopRight_CR: ["lightBlue", "red"],
  },
  green: {
    squareBottomRight_SR: ["lightBlue", "lightPurple"],
    semiCircleTopRight_S: ["pink"],
    squareTopLeft_TC: ["lightBlue", "pink"],
    triangleBottomRight_SR: ["orange", "green"],
    circleTopLeft_TR: ["lightBlue", "orange"],
    triangleTopCentre_S: ["orange"],
    squareTopRight_CR: ["lightBlue", "lightPurple"],
  },
  pink: {
    squareBottomRight_SR: ["lightBlue", "red"],
    semiCircleTopRight_S: ["lightBlue"],
    squareTopLeft_TC: ["yellow", "pink"],
    triangleBottomRight_SR: ["yellow", "darkBlue"],
    circleTopLeft_TR: ["lightBlue", "pink"],
    triangleTopCentre_S: ["yellow"],
    squareTopRight_CR: ["lightBlue", "yellow"],
  },
  purple: {
    squareBottomRight_SR: ["yellow", "lightPurple"],
    semiCircleTopRight_S: ["lightBlue"],
    squareTopLeft_TC: ["lightBlue", "orange"],
    triangleBottomRight_SR: ["yellow", "orange"],
    circleTopLeft_TR: ["lightBlue", "lightPurple"],
    triangleTopCentre_S: ["yellow"],
    squareTopRight_CR: ["orange", "yellow"],
  },
};

const layoutConfigs: Record<ShapeLayout, LayoutConfig> = {
  squareBottomRight_SR: {
    clipPath: () => (
      <rect x="1531" y="1106" width="1467" height="1467" rx="45" ry="45" />
    ),
    image: { x: "1531", y: "1106", size: "1467" },
    shapes: ({ colors }) => (
      <>
        <rect
          fill={HEX_CODES[colors[0]]}
          x="-250"
          y="736"
          width="2289.76"
          height="836"
          rx="45"
          ry="45"
          transform="rotate(-19.8)"
        />
        <path
          fill={HEX_CODES[colors[1]]}
          d="m1121 2532c0 23-20 42-43 40-322-23-576-299-576-636s254-613 576-636c23-2 43 17 43 40v1191z"
        />
      </>
    ),
  },
  squareTopLeft_TC: {
    clipPath: () => (
      <rect
        rx="45"
        ry="45"
        width="1595"
        height="1595"
        x="660"
        y="-226"
        transform="rotate(18.27)"
      />
    ),
    image: { x: "200", y: "-10", size: "2020" },
    shapes: ({ colors }) => (
      <>
        <path
          fill={HEX_CODES[colors[0]]}
          d="m40 2834h947c22 0 40-18 40-40v-993c0-36-44-54-69-28l-947 993c-24 25-6 68 29 68z"
        />
        <circle fill={HEX_CODES[colors[1]]} cx="2290" cy="2210" r="620" />
      </>
    ),
  },
  triangleTopCentre_S: {
    clipPath: () => (
      <path d="m2363 41v1751c0 30-15 45-45 45h-1754c-32 0-52-42-26-69l1752-1752c31-29 73-11 73 25z" />
    ),
    image: { x: "520", y: "0", size: "1850" },
    shapes: ({ colors }) => (
      <path
        fill={HEX_CODES[colors[0]]}
        d="m663 2717c-23 0-42-20-41-43 22-464 408-834 879-834 472 0 857 370 879 834 1 23-18 43-41 43h-1676z"
      />
    ),
  },
  semiCircleTopRight_S: {
    clipPath: () => (
      <path d="m288 494c-32-32-15-54 11-78 643-582 1592-539 2180 45 599 595 614 1574 61 2170-41 52-63 53-96 18z" />
    ),
    image: { x: "265", y: "0", size: "2680" },
    shapes: ({ colors }) => (
      <rect
        fill={HEX_CODES[colors[0]]}
        x="312"
        y="1585"
        width="1100"
        height="1100"
        rx="58"
        ry="58"
      />
    ),
  },
  circleTopLeft_TR: {
    clipPath: () => <circle cx="833" cy="825" r="825" />,
    image: { x: "8", y: "0", size: "1650" },
    shapes: ({ colors }) => (
      <>
        <rect
          fill={HEX_CODES[colors[0]]}
          x="355"
          y="1651"
          width="2290"
          height="836"
          rx="45"
          ry="45"
        />
        <path
          fill={HEX_CODES[colors[1]]}
          d="m1701 1648h1203c36 0 54-44 29-69l-1203-1203c-26-26-70-8-70 28v1203c0 23 19 41 41 41z"
        />
      </>
    ),
  },
  triangleBottomRight_SR: {
    clipPath: () => (
      <path d="m2046 1188c-13-1-27 6-34 20l-820 1455c-19 38-12 68 38 68l1662 0c38 0 49-35 35-59l-849-1463c-7-12-20-19-33-19z" />
    ),
    image: { x: "1182", y: "1080", size: "1750" },
    shapes: ({ colors }) => (
      <>
        <rect
          fill={HEX_CODES[colors[0]]}
          transform="rotate(11)"
          x="530"
          y="-60"
          height="836"
          width="2290"
          rx="45"
          ry="45"
          transform-origin="1500,1000"
        />
        <path
          fill={HEX_CODES[colors[1]]}
          d="m898.67 990.94c0.4-22.82-18.35-41.36-41.17-40.69-470.04 13.8-850.91 397.34-859.25 875.31s358.91 874.57 828.18 904.76c22.78 1.47 42.16-16.41 42.56-39.23l29.68-1700.2z"
        />
      </>
    ),
  },
  squareTopRight_CR: {
    clipPath: () => <rect width="1465" height="1465" rx="45" ry="45" />,
    image: { x: "0", y: "0", size: "1465" },
    shapes: ({ colors }) => (
      <>
        <rect
          fill={HEX_CODES[colors[0]]}
          x="706"
          y="1467"
          width="2289"
          height="836"
          rx="45"
          ry="45"
        />
        <circle fill={HEX_CODES[colors[1]]} r="580" cx="2045" cy="885" />
      </>
    ),
  },
};

const CompositeImage = ({
  src,
  layout,
  theme = "blue",
}: CompositeImageProps) => {
  const { clipPath: ClipPath, image, shapes: Shapes } = layoutConfigs[layout];
  const colors = colorCombos[theme][layout];

  const clipPathId = `clip-path-${layout}-${
    src
      .split("/")
      .pop()
      ?.replace(/[^a-zA-Z0-9]/g, "") || ""
  }`;

  return (
    <svg viewBox="0 0 3000 3000">
      <defs>
        <clipPath id={clipPathId}>
          <ClipPath />
        </clipPath>
      </defs>
      <Shapes colors={colors} />
      <image
        href={src}
        x={image.x}
        y={image.y}
        width={image.size}
        height={image.size}
        preserveAspectRatio="xMidYMid slice"
        clipPath={`url(#${clipPathId})`}
      />
    </svg>
  );
};

export default CompositeImage;
