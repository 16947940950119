import React from "react";
import Head from "next/head";
import { Layout } from "./Layout";
import { Page } from "../tina/__generated__/types";
import Hero from "./Hero";
import Testimonial from "./Testimonial";
import TextAndImage from "./TextAndImage";
import Image from "./Image";
import BodyText, { ContentBlock } from "./BodyText";
import Map from "./Map";
import ContactForm from "./ContactForm";
import Profiles, { ProfileCard } from "./Profiles";
import { generateSlug } from "../utils/slugify";
import { Order, TextSafePalette } from "./constants";
import { ShapeLayout } from "./CompositeImage";

interface PageContentProps {
  page: Page;
  footerData: any;
  headerData: any;
  isHomePage?: boolean;
}

export const PageContent: React.FC<PageContentProps> = ({
  page,
  footerData,
  headerData,
  isHomePage,
}) => {
  const { blocks, theme } = page;
  const pageTheme = theme as TextSafePalette;

  const jumplinkableBlocks = blocks.filter((block) =>
    "showInJumplinks" in block ? block.showInJumplinks : false
  );
  const jumplinks = jumplinkableBlocks.map((block) => ({
    // @ts-expect-error: I know that jumplinkable blocks always have a heading, but typescript doesn't
    title: block.heading,
    // @ts-expect-error
    id: generateSlug(block.heading),
  }));

  return (
    <Layout
      footerData={footerData}
      headerData={headerData}
      pageTheme={pageTheme}
    >
      <Head>
        <title>{page.title}</title>
      </Head>
      {blocks.map((block, index) => {
        switch (block.__typename) {
          case "PageBlocksHero":
            return (
              <Hero
                key={index}
                {...block}
                theme={block.theme as TextSafePalette}
                pageTheme={pageTheme}
                jumplinks={block.showJumplinks ? jumplinks : null}
                isHomePageHero={isHomePage}
              />
            );
          case "PageBlocksBodyText":
            return (
              <BodyText
                key={index}
                {...block}
                content={block.content as ContentBlock[]}
                theme={block.theme as TextSafePalette}
                pageTheme={pageTheme}
              />
            );
          case "PageBlocksTextAndImage":
            return (
              <TextAndImage
                key={index}
                {...block}
                shapeLayout={block.shapeLayout as ShapeLayout}
                order={block.order as Order}
                theme={block.theme as TextSafePalette}
                pageTheme={pageTheme}
              />
            );
          case "PageBlocksImage":
            return (
              <Image
                key={index}
                {...block}
                imageOneShapeLayout={block.imageOneShapeLayout as ShapeLayout}
                imageTwoShapeLayout={block.imageTwoShapeLayout as ShapeLayout}
                theme={block.theme as TextSafePalette}
                pageTheme={pageTheme}
              />
            );
          case "PageBlocksTestimonial":
            return (
              <Testimonial
                key={index}
                {...block}
                theme={block.theme as TextSafePalette | "yellow"}
                pageTheme={pageTheme}
              />
            );
          case "PageBlocksProfiles":
            return (
              <Profiles
                key={index}
                {...block}
                profileCards={block.profileCards as ProfileCard[]}
                theme={block.theme as TextSafePalette}
                pageTheme={pageTheme}
              />
            );
          case "PageBlocksMap":
            return (
              <Map
                key={index}
                {...block}
                theme={block.theme as TextSafePalette}
                pageTheme={pageTheme}
              />
            );
          case "PageBlocksContactForm":
            return (
              <ContactForm
                key={index}
                {...block}
                theme={block.theme as TextSafePalette}
                pageTheme={pageTheme}
              />
            );
          default:
            return null;
          // return <pre>{JSON.stringify(block, null, 2)}</pre>;
        }
      })}
    </Layout>
  );
};
