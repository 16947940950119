import React from "react";
import Image from "next/image";
import CompositeImage, { ShapeLayout } from "./CompositeImage";
import { TextSafePalette } from "./constants";

type FigureProps = {
  image: string;
  imageAlt?: string;
  imageCaption?: string;
  className?: string;
  shapeLayout?: ShapeLayout;
  theme?: TextSafePalette;
  isAboveFold?: boolean;
};

const Figure = ({
  image,
  imageAlt,
  imageCaption,
  className,
  shapeLayout,
  theme,
  isAboveFold,
}: FigureProps) => (
  <figure className={className}>
    {shapeLayout ? (
      <CompositeImage src={image} layout={shapeLayout} theme={theme} />
    ) : (
      <Image
        src={image}
        alt={imageAlt || ""}
        sizes="100vw, (min-width: 768px) 50vw"
        width={500}
        height={500}
        loading={isAboveFold ? undefined : "lazy"}
        priority={isAboveFold ? true : false}
      />
    )}
    {imageCaption && <figcaption>{imageCaption}</figcaption>}
  </figure>
);
export default Figure;
