import Head from "next/head";
import Header, { HeaderLink } from "./Header";
import Footer, { FooterProps } from "./Footer";
import { TextSafePalette } from "./constants";

export const Layout = ({
  children,
  headerData,
  footerData,
  pageTheme,
}: {
  children: React.ReactNode;
  headerData: {
    headerLinks: HeaderLink[];
  };
  footerData: FooterProps;
  pageTheme: TextSafePalette;
}) => (
  <>
    <Head>
      <title>Kidzone Childcare Centre</title>
      <link rel="icon" href="/favicon.ico" sizes="32x32" />
      <link rel="icon" href="/icon.svg" type="image/svg+xml" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link rel="manifest" href="/manifest.json" />
      <meta
        name="description"
        content="A not-for-profit parent-run cooperative childcare centre for ages 1-5 in Newtown, Wellington"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>
    <Header links={headerData.headerLinks} />
    <main>{children}</main>
    <Footer data={footerData} pageTheme={pageTheme} />
  </>
);
