import React from "react";
import Link from "next/link";
import { TinaMarkdown, TinaMarkdownContent } from "tinacms/dist/rich-text";

export type TextProps = {
  heading?: string;
  richText: TinaMarkdownContent;
  ctaText?: string;
  ctaLink?: string;
  HeadingLevel?: "h2" | "h3";
};

const Text = ({
  heading,
  richText,
  ctaText,
  ctaLink,
  HeadingLevel = "h2",
}: TextProps) => {
  return (
    <div className="text-content">
      {heading && <HeadingLevel>{heading}</HeadingLevel>}

      {richText && <TinaMarkdown content={richText} />}

      {ctaLink && (
        <Link className="btn" href={ctaLink}>
          {ctaText ? ctaText : ctaLink}
        </Link>
      )}
    </div>
  );
};

export default Text;
