import { TextSafePalette } from "./constants";

export type FooterProps = {
  theme?: TextSafePalette;
  titleLineOne?: string;
  titleLineTwo?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  openHours?: string;
  openDays?: string;
  phoneNumber?: string;
  email?: string;
};
const Footer = ({
  data,
  pageTheme,
}: {
  data: FooterProps;
  pageTheme: TextSafePalette;
}) => {
  if (!data) return null;

  const {
    titleLineOne,
    titleLineTwo,
    addressLineOne,
    addressLineTwo,
    openHours,
    openDays,
    phoneNumber,
    email,
  }: FooterProps = data;

  return (
    <footer className={`footer theme--${pageTheme}`}>
      <div className="footer__content">
        {(titleLineOne || titleLineTwo) && (
          <h2>
            <div>{titleLineOne}</div>
            <div>{titleLineTwo}</div>
          </h2>
        )}

        {(addressLineOne ||
          addressLineTwo ||
          openHours ||
          openDays ||
          phoneNumber ||
          email) && (
          <div className="footer__details">
            {(addressLineOne || addressLineTwo) && (
              <div className="footer__two-liner">
                {addressLineOne && <div>{addressLineOne}</div>}
                {addressLineTwo && <div>{addressLineTwo}</div>}
              </div>
            )}

            {(openHours || openDays) && (
              <div className="footer__two-liner">
                {openHours && <div>{openHours}</div>}
                {openDays && <div>{openDays}</div>}
              </div>
            )}

            <div className="footer__links">
              {phoneNumber && <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>}

              {email && <a href={`mailto:${email}`}>{email}</a>}
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
