import React from "react";
import { TinaMarkdownContent, TinaMarkdown } from "tinacms/dist/rich-text";

type TableCell = {
  content: TinaMarkdownContent;
};

type TableRow = {
  rowHeading?: string;
  displayRowHeading: boolean;
  isHeaderRow: boolean;
  cells: TableCell[];
};

export type TableProps = {
  heading?: string;
  intro?: TinaMarkdownContent;
  rows?: TableRow[];
  HeadingLevel: "h2" | "h3";
};

const Table = ({ heading, rows, intro, HeadingLevel = "h2" }: TableProps) => (
  <div className="table-block">
    {(heading || intro) && (
      <div>
        {heading && <HeadingLevel>{heading}</HeadingLevel>}
        {intro && <TinaMarkdown content={intro} />}
      </div>
    )}
    <div className="table-scroll-wrapper">
      <table>
        <tbody>
          {rows &&
            rows.length > 0 &&
            rows.map(
              (
                { displayRowHeading, rowHeading, isHeaderRow, cells },
                rowIndex
              ) => (
                <tr key={rowIndex}>
                  {displayRowHeading && rowHeading && (
                    <th scope="row">{rowHeading}</th>
                  )}
                  {cells &&
                    cells.length > 0 &&
                    cells.map(({ content }, cellIndex) => {
                      const CellComponent = isHeaderRow ? "th" : "td";
                      return (
                        <CellComponent key={cellIndex}>
                          <TinaMarkdown content={content} />
                        </CellComponent>
                      );
                    })}
                </tr>
              )
            )}
        </tbody>
      </table>
    </div>
  </div>
);

export default Table;
