import React from "react";
import { TextSafePalette } from "./constants";

type TestimonialProps = {
  pageTheme: TextSafePalette;
  theme?: TextSafePalette | "yellow";
  quote: string;
  author?: string;
  role?: string;
};

const Testimonial = ({
  pageTheme,
  theme = pageTheme,
  quote,
  author,
  role,
}: TestimonialProps) => (
  <section className={`testimonial block theme--${theme}`}>
    <figure>
      <svg viewBox="0 0 96 76" aria-hidden>
        <path
          fill="var(--theme-color-primary)"
          d="m36 76v-35.9h-16.5l0 0c.5-4 1.5-7.8 3-11.4 2.2-5.5 6.4-11.7 12.5-18.5l-13.7-10.2c-7.8 8-13.2 16.1-16.4 24.3a73.7 73.7 90 00-4.8 26.7v25zm60 0v-35.9h-16.5l0 0c.5-4 1.5-7.8 3-11.4 2.2-5.5 6.4-11.7 12.5-18.5l-13.7-10.2c-7.8 8-13.2 16.1-16.4 24.3a73.7 73.7 90 00-4.8 26.7v25z"
        />
      </svg>

      <blockquote>{quote}</blockquote>
      {(author || role) && (
        <figcaption>
          {author}
          {author && role ? ", " : ""}
          {role}
        </figcaption>
      )}
    </figure>
  </section>
);

export default Testimonial;
