import Link from "next/link";
import { MouseEventHandler } from "react";

const HeaderLogo = () => (
  <svg
    aria-hidden
    className="header__logo"
    viewBox="0 0 306 59"
    fill="currentColor"
  >
    <path
      className="logo__k"
      d="M1 0 0 59l14.4-1L16 38h1l11 20 18-3-13.8-26L48 1C43.2 1 31.2.6 31.2.6L19 19h-1l1-18L1 0"
    />
    <path className="logo__i" d="M51 3v53l17-1 3-52H51" />
    <path
      className="logo__d"
      d="M94.3 3a100 100 0 0 0-15.8 1.7l-.5 53c8.5 0 14.2-.7 18.8-1.2 8.7-.8 17.2-7.7 21.2-15.5 3.9-7.5 3.4-17.4.5-25.4-1.1-3-3.7-5.4-6.3-7.2a35.4 35.4 0 0 0-18-5.4ZM96 17.9c3.2 0 7.5 3.4 9.4 6.3 1.6 2.4 2 6 1 8.7-2.2 5.2-7.2 11.4-13.3 10.7V31l.2-12 1-.7A5 5 0 0 1 96 18z"
    />
    <path
      className="logo__z"
      d="m123.3 43 21-26.8-19 .1V3l34 .6v13l-17 25v.9h16.5L158 55l-33.5 1.3z"
    />
    <path
      className="logo__o"
      d="M184.8 5c-1.8 0-3.8 0-5.8.5-30.5 5.8-21 54.6 10 49.2 29.5-5.2 23.5-49.4-4.2-49.8zm-.4 16c8.6.2 11.9 14.9 2.6 18.4-10.9 4-15.4-14.1-6-17.8 1.2-.5 2.4-.7 3.4-.6z"
    />
    <path
      className="logo__n"
      d="M214 2v54h16V28l17.4 26H263l1-52h-16l1 26-19.2-25.8L214 2"
    />
    <path
      className="logo__e"
      d="M270 2v54h34l1-16h-18v-7h13l1-11h-13v-7h18V1l-36 1"
    />
  </svg>
);

const HomeLink = ({
  ariaCurrentPage,
  isModalButton = false,
  onClick,
}: {
  ariaCurrentPage: boolean;
  isModalButton?: boolean;
  onClick?: MouseEventHandler;
}) =>
  isModalButton ? (
    <button
      className="header__logo-link"
      aria-label="close the menu"
      onClick={onClick}
    >
      <HeaderLogo />
    </button>
  ) : (
    <Link
      className="header__logo-link"
      href="/"
      aria-label="Kidzone, Home"
      aria-current={ariaCurrentPage ? "page" : undefined}
    >
      <HeaderLogo />
    </Link>
  );

export default HomeLink;
