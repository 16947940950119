const Hamburger = () => (
  <svg className="mobile-menu__hamburger" viewBox="0 0 48 48" aria-hidden>
    <g stroke="currentColor" strokeWidth="4">
      <path
        className="mobile-menu__top-bun"
        transform-origin="2.9 6.3"
        d="m0 4 h48"
      />
      <path
        className="mobile-menu__patty"
        transform-origin="24 24"
        d="m0 24 h48"
      />
      <path
        className="mobile-menu__bottom-bun"
        transform-origin="2.9 41.7"
        d="m0 44 h48"
      />
    </g>
  </svg>
);

export default Hamburger;
