import React from "react";
import { TextSafePalette } from "./constants";
import { TinaMarkdown, TinaMarkdownContent } from "tinacms/dist/rich-text";
import Figure from "./Figure";
import { generateSlug } from "../utils/slugify";
export type ProfileCard = {
  name: string;
  role: string;
  drawing: string;
  drawingAlt: string;
  drawingCaption: string;
  photo: string;
  about: TinaMarkdownContent;
};
type ProfilesProps = {
  pageTheme: TextSafePalette;
  theme?: TextSafePalette;
  heading?: string;
  intro?: TinaMarkdownContent;
  profileCards?: ProfileCard[];
  showInJumplinks?: boolean;
};

const Profiles = ({
  pageTheme,
  theme = pageTheme,
  heading,
  intro,
  profileCards,
  showInJumplinks,
}: ProfilesProps) => (
  <section className={`profiles block full-width theme--${theme}`}>
    <div className="text-content">
      {heading && (
        <h2 id={showInJumplinks ? generateSlug(heading) : undefined}>
          {heading}
        </h2>
      )}
      {intro && <TinaMarkdown content={intro} />}
    </div>

    <ul>
      {profileCards &&
        profileCards.length > 0 &&
        profileCards.map(
          (
            {
              name,
              role,
              drawing,
              drawingAlt,
              drawingCaption,
              photo,
              about,
            }: ProfileCard,
            index: number
          ) => (
            <li key={`${name}-${index}`} className="profiles__card">
              {(drawing || photo) && (
                <div className="profiles__card-image-wrapper">
                  {photo && <Figure image={photo} imageAlt={name} />}
                  {drawing && (
                    <Figure
                      image={drawing}
                      imageAlt={drawingAlt || name}
                      imageCaption={drawingCaption}
                      className={
                        drawing && photo ? "profiles__initial-image" : undefined
                      }
                    />
                  )}
                </div>
              )}

              {(name || role || about) && (
                <details>
                  {(name || role) && (
                    <summary>
                      <header>
                        {name && <h3>{name}</h3>}
                        {role && <div>{role}</div>}
                      </header>
                      <svg viewBox="-12 -12 24 24">
                        <path
                          d="M-8.5-4 0 4.5 8.5-4"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinejoin="round"
                          strokeLinecap="round"
                        />
                      </svg>
                    </summary>
                  )}

                  {about && <TinaMarkdown content={about} />}
                </details>
              )}
            </li>
          )
        )}
    </ul>
  </section>
);

export default Profiles;
