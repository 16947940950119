import React from "react";
import Link from "next/link";
import { Order, TextSafePalette } from "./constants";
import { TinaMarkdown, TinaMarkdownContent } from "tinacms/dist/rich-text";
import Figure from "./Figure";
import { ShapeLayout } from "./CompositeImage";
import { generateSlug } from "../utils/slugify";

type TextAndImageProps = {
  pageTheme: TextSafePalette;
  theme?: TextSafePalette;
  order?: Order;
  heading?: string;
  richText?: TinaMarkdownContent;
  ctaText?: string;
  ctaLink?: string;
  image?: string;
  imageAlt?: string;
  imageCaption?: string;
  shapeLayout?: ShapeLayout;
  showInJumplinks?: boolean;
};

const TextAndImage = ({
  pageTheme,
  theme = pageTheme,
  order = "Image last",
  heading,
  richText,
  ctaText,
  ctaLink,
  image,
  imageAlt,
  imageCaption,
  shapeLayout,
  showInJumplinks,
}: TextAndImageProps) => (
  <section className={`text-and-image block theme--${theme}`}>
    {order === "Image first" && image && (
      <Figure
        image={image}
        imageAlt={imageAlt}
        imageCaption={imageCaption}
        shapeLayout={shapeLayout}
        theme={theme}
      />
    )}

    <div className="text-content">
      <h2 id={showInJumplinks ? generateSlug(heading) : undefined}>
        {heading}
      </h2>
      {richText && <TinaMarkdown content={richText} />}
      {ctaLink && (
        <Link className="btn" href={ctaLink}>
          {ctaText}
        </Link>
      )}
    </div>

    {order === "Image last" && image && (
      <Figure
        image={image}
        imageAlt={imageAlt}
        imageCaption={imageCaption}
        shapeLayout={shapeLayout}
        theme={theme}
      />
    )}
  </section>
);

export default TextAndImage;
