import React from "react";
import Figure from "./Figure";
import { TextSafePalette } from "./constants";
import { ShapeLayout } from "./CompositeImage";

type ImageProps = {
  pageTheme: TextSafePalette;
  theme?: TextSafePalette;
  imageOne?: string;
  imageOneAlt?: string;
  imageOneCaption?: string;
  imageOneShapeLayout: ShapeLayout;
  imageTwo?: string;
  imageTwoAlt?: string;
  imageTwoCaption?: string;
  imageTwoShapeLayout: ShapeLayout;
};
const Image = ({
  pageTheme,
  theme = pageTheme,
  imageOne,
  imageOneAlt,
  imageOneCaption,
  imageOneShapeLayout,
  imageTwo,
  imageTwoAlt,
  imageTwoCaption,
  imageTwoShapeLayout,
}: ImageProps) => (
  <section className="image block">
    {imageOne && (
      <Figure
        image={imageOne}
        imageAlt={imageOneAlt}
        imageCaption={imageOneCaption}
        shapeLayout={imageOneShapeLayout}
        theme={theme}
      />
    )}
    {imageTwo && (
      <Figure
        image={imageTwo}
        imageAlt={imageTwoAlt}
        imageCaption={imageTwoCaption}
        shapeLayout={imageTwoShapeLayout}
        theme={theme}
      />
    )}
  </section>
);

export default Image;
