import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { TextSafePalette } from "./constants";
import HomeLink from "./HomeLink";
import Hamburger from "./Hamburger";

export type HeaderLink = {
  label: string;
  url: string;
  theme: TextSafePalette;
};

type HeaderProps = {
  links: HeaderLink[];
};

const Header = ({ links }: HeaderProps) => {
  const router = useRouter();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const showModal = () => {
    dialogRef.current?.showModal();
    setIsMenuOpen(true);
  };

  const closeModal = () => {
    dialogRef.current?.close();
    setIsMenuOpen(false);
  };

  const isCurrentPath = (url: string) => {
    return router.pathname === url;
  };

  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;
    dialog.addEventListener("cancel", () => setIsMenuOpen(false));
  });

  return (
    <header className="header">
      <nav>
        <HomeLink ariaCurrentPage={isCurrentPath("/")} />
        <ul className="desktop-menu" role="navigation">
          {links.map((link, index) => (
            <li key={link.label + index}>
              <Link
                className={`btn theme theme--${link.theme || "blue"}`}
                href={link.url}
                aria-current={isCurrentPath(link.url) ? "page" : undefined}
              >
                {link.label}
              </Link>
            </li>
          ))}
        </ul>

        <div
          className={`mobile-menu ${
            isMenuOpen ? "mobile-menu--is-open" : "mobile-menu--is-closed"
          }`}
          role="navigation"
        >
          <button
            aria-label="show the menu"
            onClick={showModal}
            className="mobile-menu__menu-button"
          >
            <Hamburger />
          </button>

          <dialog ref={dialogRef} className="mobile-menu__dialog">
            <div>
              <HomeLink
                ariaCurrentPage={isCurrentPath("/")}
                isModalButton={true}
                onClick={closeModal}
              />
              <button
                aria-label="close the menu"
                onClick={closeModal}
                className="mobile-menu__menu-button"
              >
                <Hamburger />
              </button>
            </div>
            <ul>
              {links.map((link, index) => (
                <li key={link.label + index}>
                  <Link
                    className={`theme theme--${link.theme || "blue"}`}
                    href={link.url}
                    aria-current={isCurrentPath(link.url) ? "page" : undefined}
                    onClick={closeModal}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </dialog>
        </div>
      </nav>
    </header>
  );
};

export default Header;
