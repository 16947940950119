import { ChangeEvent, FormEvent, useState } from "react";
import { TextSafePalette } from "./constants";
import { TinaMarkdown, TinaMarkdownContent } from "tinacms/dist/rich-text";
import { generateSlug } from "../utils/slugify";

type ContactFormProps = {
  pageTheme: TextSafePalette;
  theme?: TextSafePalette;
  heading?: string;
  intro?: TinaMarkdownContent;
  showInJumplinks?: boolean;
};

const ContactForm = ({
  pageTheme,
  theme = pageTheme,
  heading,
  intro,
  showInJumplinks,
}: ContactFormProps) => {
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleServerResponse = (ok: boolean, msg: string) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        name: "",
        email: "",
        message: "",
      });
    } else {
      setStatus((prevStatus) => ({
        ...prevStatus,
        info: { error: true, msg: msg },
      }));
    }
  };
  const handleOnChange = (e: ChangeEvent) => {
    const input = e.target;
    if (
      !(
        input instanceof HTMLInputElement ||
        input instanceof HTMLTextAreaElement
      )
    ) {
      throw new Error("Expected input not found");
    }
    e.persist();
    setInputs((prev) => ({
      ...prev,
      [input.id]: input.value,
    }));
    setStatus({
      submitted: false,
      submitting: false,
      info: { error: false, msg: null },
    });
  };
  const handleOnSubmit = (e: FormEvent) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));

    fetch("https://formspree.io/f/xanwkpwd", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(inputs),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(error.error);
          });
        }
        handleServerResponse(
          true,
          "Thank you, your message has been submitted."
        );
      })
      .catch((error) => {
        handleServerResponse(false, error.message);
      })
      .finally(() => {
        setStatus((prevStatus) => ({ ...prevStatus, submitting: false }));
      });
  };
  return (
    <section
      className={`contact-form block full-width theme--${theme || "blue"}`}
    >
      {heading && (
        <h2 id={showInJumplinks ? generateSlug(heading) : undefined}>
          {heading}
        </h2>
      )}
      {intro && <TinaMarkdown content={intro} />}
      <form onSubmit={handleOnSubmit}>
        {!(status.submitted && !status.info.error) && (
          <>
            <label htmlFor="name">
              Name
              <input
                id="name"
                type="text"
                name="sender"
                autoComplete="name"
                onChange={handleOnChange}
                required
                value={inputs.name}
                placeholder=" "
              />
            </label>
            <label htmlFor="email">
              Email
              <input
                id="email"
                type="email"
                name="_replyto"
                autoComplete="email"
                onChange={handleOnChange}
                required
                value={inputs.email}
                placeholder=" "
              />
            </label>
            <label htmlFor="message">
              Message
              <textarea
                id="message"
                name="message"
                onChange={handleOnChange}
                required
                value={inputs.message}
                placeholder=" "
              />
            </label>
          </>
        )}
        <button
          type="submit"
          disabled={status.submitting || status.submitted}
          className={
            status.submitted && !status.info.error ? "success" : undefined
          }
        >
          {!status.submitting
            ? !status.submitted
              ? "Send"
              : "Sent"
            : "Sending..."}
        </button>
      </form>
      {status.info.error && (
        <div className="error">Error: {status.info.msg}</div>
      )}
      {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
    </section>
  );
};

export default ContactForm;
