export const PALETTE = [
  "lightBlue",
  "blue",
  "darkBlue",
  "green",
  "yellow",
  "orange",
  "red",
  "pink",
  "purple",
  "lightPurple",
] as const;
export type Palette = (typeof PALETTE)[number];

export const textSafePalette = [
  "blue",
  "green",
  "orange",
  "pink",
  "purple",
] as const;

export type TextSafePalette = (typeof textSafePalette)[number];

export const ORDER_OPTIONS = ["Image first", "Image last"] as const;
export type Order = (typeof ORDER_OPTIONS)[number];

export const HEX_CODES: Record<Palette, `#${string}`> = {
  lightBlue: "#c3eaea",
  blue: "#0088c7",
  darkBlue: "#1e4b5d",
  green: "#008a43",
  yellow: "#fdc824",
  orange: "#f58220",
  red: "#d71921",
  pink: "#f05c74",
  purple: "#825397",
  lightPurple: "#AC8EBA",
} as const;
